
export enum ErrorCode {
  ALREADY_REGISTERED,
  INVALID_PHONE,
  DEPOSIT_IN_PROGRESS,
  NO_FUNDS_IN_YOYO_WALLET,
  UPDATE_INVALID_NO_CHANGE,
  BANK_ACCOUNT_UPDATE_REQUIRED,
  NOTIFY_FEE_CHANGES,
  MISSING_ADDRESS,
}

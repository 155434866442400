<template>
  <!-- <v-container class="bg"> -->
  <v-row class="row my-0">
    <v-col cols="12" md="5" class="side hidden-sm-and-down">
      <div class="text-side">
        <h1>Welcome Back!</h1>
        <br />
        <span
          >To keep connected with us please log in with your personal
          info.</span
        >
      </div>

      <div class="redes">
        <div>
          <span>Contact us through</span>
        </div>

        <div class="icons mt-3">
          <a
            target="_blank"
            href="https://instagram.com/tiratuyoyo?utm_medium=copy_link"
          >
            <v-icon class="p-2 icon">mdi-instagram</v-icon>
          </a>
          <a target="_blank" href="https://wa.me/18299462555">
            <v-icon class="p-2 icon">mdi-whatsapp</v-icon>
          </a>
          <a target="_blank" href="https://yoyo.do">
            <v-icon class="p-2 icon">mdi-web</v-icon>
          </a>
        </div>
      </div>
    </v-col>

    <v-col cols="12" md="7">
      <div class="flex justify-center md:justify-start pt-12 md:-mb-24">
        <v-avatar rounded size="128" class="avatar">
          <img src="https://app.yoyo.do/assets/images/Yoyo.png" alt="John" />
        </v-avatar>
      </div>
      <div class="">
        <div class="w-full flex flex-wrap">
          <!-- Login Section -->
          <div class="w-full md:w-1/2 flex flex-col">
            <div
              class="
                flex flex-col
                justify-center
                md:justify-start
                my-auto
                pt-8
                md:pt-0
                px-8
                md:px-24
                lg:px-32
              "
            >
              <div class="error-message">
                <v-alert v-if="error" class="mb-0" type="error">
                  {{ error }}
                </v-alert>
              </div>
              <validation-observer ref="observer" v-slot="{ invalid }">
                <form
                  @submit.prevent="submit"
                  class="flex flex-col sm:pt-3 md:py-12 md:px-28"
                  onsubmit="event.preventDefault();"
                >
                  <div class="flex flex-col md:pt-4 md:px-28">
                    <validation-provider
                      v-slot="{ errors }"
                      name="email"
                      rules="required|email"
                    >
                      <label for="email" class="text-sm">Correo</label>
                      <br />
                      <input
                        v-model="email"
                        type="email"
                        id="email"
                        :error-messages="errors"
                        placeholder="yoyo@gmail.com"
                        class="
                          shadow
                          appearance-none
                          border
                          rounded
                          w-full
                          py-3
                          px-2
                          text-gray-700
                          mt-3
                          leading-tight
                          focus:outline-none focus:shadow-outline
                        "
                      />
                      <span style="color: red">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>

                  <div class="flex flex-col md:pt-4 md:px-28">
                    <validation-provider
                      v-slot="{ errors }"
                      name="password"
                      rules="required"
                    >
                      <label for="password" class="text-sm">Contraseña</label>
                      <br />
                      <input
                        v-model="password"
                        :error-messages="errors"
                        type="password"
                        id="password"
                        placeholder="Contraseña"
                        class="
                          shadow
                          appearance-none
                          border
                          rounded
                          w-full
                          py-3
                          px-2
                          text-gray-700
                          mt-3
                          leading-tight
                          focus:outline-none focus:shadow-outline
                        "
                      />
                      <span style="color: red">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div align="center">
                    <v-btn
                      :disabled="invalid"
                      class="button"
                      rounded
                      x-large
                      type="submit"
                      >Iniciar Sesión
                    </v-btn>
                  </div>
                </form>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
  <!-- </v-container> -->
</template>

<script>
import { required, email } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { AUTH_HEADER } from "@/config/constants";
extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("email", {
  ...email,
  message: "Email must be valid",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    email: "",
    password: "",
    error: "",
  }),

  methods: {
    async submit() {
      const resp = await fetch(
        `${process.env.VUE_APP_API_URL}/auth/authenticate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            [AUTH_HEADER]: process.env.VUE_APP_API_KEY,
          },
          body: JSON.stringify({
            email: this.email,
            password: this.password,
          }),
        }
      );

      const { ok, data, errors, errorMessage } = await resp.json();
      if (ok) {
        localStorage.setItem("session", data);
        this.$router.push("/");
      }
      if (!ok) {
        if (errorMessage) this.error = errorMessage;
        else if (errors) {
          this.error = errors[0].msg;
        } else {
          this.error = resp.statusText;
        }
      }
    },
    clear() {
      this.name = "";
      this.phoneNumber = "";
      this.email = "";
      this.select = null;
      this.checkbox = null;
      this.$refs.observer.reset();
    },
  },
};
</script>

<style scoped>
label {
  color: black;
}

.bg {
  background: white !important;
}

.icon {
  color: white !important;
  font-size: 25px;
  cursor: pointer;
}

.button {
  margin-top: 35px;
  width: 250px;
  background: #f78f6e !important;
  color: #f7f7fa;
}

.side {
  background-color: #353058;
  height: 100vh;
  text-align: center;
}

.text-side {
  width: 60%;
  padding: 20vh 0;
  margin: 0 auto;
}

span {
  color: #f7f7fa;
  margin-top: 20px !important;
}

h1 {
  font-size: 50px;
  color: white;
}

.wrapper {
  width: 60%;
  margin: 80px auto;
  padding: 15px;
  border-radius: 5px;
  box-shadow: bisque 2px;
  /* text-align: center; */
}

.wrapper .error-message {
  width: 100%;
  /* text-align: center; */
}

.error-message v-alert {
  margin: 0px !important;
}

.icon {
  font-size: 40px !important;
}
</style>

<template>
  <v-card :loading="loading" class="mx-auto my-12" max-width="374">
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-img
      height="250"
      :src="merchant.logo || 'https://app.yoyo.do/assets/images/Yoyo.png'"
    ></v-img>

    <v-card-title>{{ merchant.companyName }}</v-card-title>

    <v-card-text>
      <v-row align="center" class="mx-0">
        <v-rating
          color="amber"
          dense
          half-increments
          readonly
          size="14"
        ></v-rating>

        <div class="grey--text ms-4">{{merchant.code}}</div>
      </v-row>

      <div class="my-4 text-subtitle-1">{{merchant.name}}</div> 

    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    selection: 1,
  }),
  props: {
    merchant: {},
  },
  methods: {
    reserve() {
      this.loading = true;

      setTimeout(() => (this.loading = false), 4000);
    },
  },
};
</script>
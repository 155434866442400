import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/authenticate",
    name: "Login",
    component: Login,
    meta: {
      guest: true,
    },
  },
  {
    path: "/transactions",
    name: "Transactions",
    component: () => import("../views/Transactions.vue"),
  },
  {
    path: "/products",
    name: "Products",
    component: () => import("../views/Products.vue"),
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("../views/Settings.vue"),
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  base: "/merchants/",
});

// Meta Handling
router.beforeEach((to, from, next) => {
  if (to.query.token) {
    localStorage.setItem("session", to.query.token as string);
    delete to.query.token;
    return next({
      path: to.path,
      query: to.query
    });
  }

  let jwt = localStorage.getItem("session");
  if (!to.matched.some((record) => record.meta.guest)) {
    if (jwt == null) {
      next({
        path: "/authenticate",
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (jwt == null) {
      next();
    } else {
      next({
        path: "/",
        params: { nextUrl: to.fullPath },
      });
    }
  } else {
    next();
  }
});

export default router;

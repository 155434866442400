<template>
  <v-dialog v-model="show" max-width="290">
    <v-card>
      <v-card-title class="text-h5">
        {{ title }}
      </v-card-title>

      <v-card-text>
        {{ text }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          v-for="btn in buttons"
          :color="(btn.color ? btn.color : 'green') + ' green darken-1'"
          :key="btn.text"
          text
          @click="close(btn.role === 'cancel' ? false : true) && btn.action()"
        >
          {{ btn.text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script >
export default {
  data: () => ({
    show: false,
    text: "",
    title: "",
    buttons: [{}],
    resolver: (value) => {},
    rejecter: (reason) => {},
  }),
  methods: {
    close(agree) {
      this.show = false;
      this.resolver(agree);
    },
    showAlert(opts) {
      return new Promise((resolve, reject) => {
        if (opts.title) this.title = opts.title;
        if (opts.text) this.text = opts.text;
        if (opts.buttons) this.buttons = opts.buttons;
        this.show = true;

        this.resolver = resolve;
      });
    },
  },
};
</script>








import Vue from "vue";
import Overview from "../components/Overview.vue";
import Services from "../components/Services.vue";

export default Vue.extend({
  name: "Home",
  components: {
    Overview,
    Services,
  },
  data: () => ({
    error: "",
  }),
  props: {
    merchant: {},
  },
  methods: {
    onFloatCreated(f: any) {
      this.$emit("float:created", f);
    },
  },
});

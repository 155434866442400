<template>
  <v-container class="md:mt-4">
    <v-row dense>
      <v-col cols="12" md="4">
        <v-card
          color="#494569"
          dark
          elevation="4"
          outlined
          class="card md:mt-8"
          height="370px"
        >
          <v-card-title class="text-h5">Links de pago Rapido </v-card-title>

          <v-card-subtitle class="text-justify ml-3"
            >¡Recibir pagos nunca fue tan fácil!</v-card-subtitle
          >

          <v-card-subtitle class="text-justify py-0">
            Dale click a este boton para generar un nuevo link de pagos con el
            cual podrás:</v-card-subtitle
          >

          <div class="">
            <v-list class="list" dense rounded>
              <v-list-item class="font">
                <v-list-item-icon>
                  <v-icon>mdi-circle-medium</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    >Cobrar de manera rápida.</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="font">
                <v-list-item-icon>
                  <v-icon>mdi-circle-medium</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    >Compartir por el medio que desees.</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="font">
                <v-list-item-icon>
                  <v-icon>mdi-circle-medium</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    >Recibir notificaciones de pagos y más.</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>

          <v-card-actions class="flex justify-center mt-5">
            <v-btn @click="openDialog('STANDARD')" color="#2f2a4d" depressed
              >Generar Link</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
      <link-form
        @float:created="onFloatCreated"
        :type="formType"
        @close:form="onFormClose"
        :show="dialog"
        :merchant="merchant"
      />

      <v-col cols="12" md="4">
        <v-card
          color="#2f2a4d"
          dark
          elevation="8"
          outlined
          class="card"
          height="500px"
        >
          <v-card-title class="text-h5" v-text="'Pagos con QR'"></v-card-title>

          <v-card-subtitle
            class="text-center pt-1"
            v-text="`${appUrl}/user/${merchant.username}`"
          ></v-card-subtitle>

          <div class="flex justify-center">
            <v-avatar size="200" rounded>
              <v-img :src="`${appUrl}/user/${merchant.username}`"></v-img>
            </v-avatar>
          </div>

          <v-card-actions class="flex justify-center">
            <v-btn class="ml-2 mt-5" color="#494569" icon depressed>
              <v-icon
                v-clipboard:copy="`${appUrl}/user/${merchant.username}`"
                v-clipboard:success="onCopy"
                class="p-2 icon"
                >mdi-content-copy</v-icon
              >
            </v-btn>
            <v-btn class="ml-2 mt-5" color="#494569" icon depressed>
              <a
                :href="`${appUrl}/user/${merchant.username}`"
                download rel="noopener noreferrer" target="_blank"
              >
                <v-icon class="p-2 icon">mdi-download-circle</v-icon>
              </a>
            </v-btn>
            <v-btn
              :href="`${appUrl}/user/${merchant.username}`"
              target="_blank"
              class="ml-2 mt-5"
              color="#494569"
              icon
              depressed
            >
              <v-icon class="p-2 icon">mdi-web</v-icon>
            </v-btn>
          </v-card-actions>
          <v-card-subtitle class="text-center p-5"
            >Arriba podrás encontrar tu código QR para imprimirlo y que tus
            clientes puedan pagarte con tarjeta con solo escanearlo desde el
            celular.</v-card-subtitle
          >
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card
          color="#494569"
          dark
          elevation="4"
          outlined
          class="card md:mt-8"
          height="370px"
        >
          <v-card-title class="text-h5">Links de Producto </v-card-title>

          <v-card-subtitle class="text ml-3"
            >¿Buscas cobrar de forma fácil, rápida y segura tus
            servicios?</v-card-subtitle
          >

          <v-card-subtitle class="text-justify py-0"
            >Dale clik a este boton para crear un link de un producto o servicio
            con el cual podrás:</v-card-subtitle
          >

          <div class="">
            <v-list class="list" dense rounded>
              <v-list-item class="font">
                <v-list-item-icon>
                  <v-icon>mdi-circle-medium</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    >Cambiar el logo de tu producto.</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="font">
                <v-list-item-icon>
                  <v-icon>mdi-circle-medium</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    >Utilizarlo en multiples ocaciones.</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="font">
                <v-list-item-icon>
                  <v-icon>mdi-circle-medium</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    >Actualizar cantidades y limites de uso.</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>

          <v-card-actions class="flex justify-center">
            <v-btn @click="openDialog('PRODUCT')" color="#2f2a4d" depressed>
              Generar ahora
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import LinkForm from "@/components/LinkForm.vue";
import { createFloat } from "@/utils";
export default {
  data: () => ({
    appUrl: process.env.VUE_APP_YOYO_URL,
    dialog: false,
    formType: "",
    link: {
      payer: {},
    },
    review: false,
  }),
  props: {
    merchant: {},
  },
  components: {
    LinkForm,
  },
  methods: {
    onCopy: function (e) {
      this.$emit(
        "float:created",
        createFloat({ text: "Link QR Copiado!", duration: 2000 })
      );
    },
    onFloatCreated(f) {
      this.$emit("float:created", f);
    },
    onFormClose() {
      this.dialog = false;
    },
    openDialog(type) {
      this.formType = type;
      this.dialog = true;
    },
  },
};
</script>

<style>
.card {
  border-radius: 15px !important;
}

.list {
  background-color: transparent !important;
}

.font {
  color: rgba(255, 255, 255, 0.7) !important;
}
</style>
